import React, { createContext, useState, useContext } from "react";

// Create the context
const SearchContext = createContext();

// Create a provider component
export const SearchProvider = ({ children }) => {
    const [searchValuecontext, setSearchValuecontex] = useState(""); // Shared search value

    return (
        <SearchContext.Provider value={{ searchValuecontext, setSearchValuecontex }}>
            {children}
        </SearchContext.Provider>
    );
};

// Custom hook to use the search context
export const useSearchContext = () => useContext(SearchContext);
